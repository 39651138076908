import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConstants } from 'src/app/core/config/auth-constants';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { SessionStorageService } from 'src/app/core/services/storage/session/session-storage.service';
import { PageResponse } from 'src/app/shared/models/page/page-response';
import { Product } from 'src/app/shared/models/products/product';
import { ProductFilter } from 'src/app/shared/models/products/product-filter';
import { LoaderService } from './../../core/services/loader/loader.service';
import { Customer } from './../../shared/models/customer/customer';

@Component({
  selector: 'app-home-page',
  templateUrl: './home.page.component.html',
  styleUrls: ['./home.page.component.scss'],
})
export class HomeComponent {
  public pageResponseProduct: PageResponse = new PageResponse();
  public products: Product[] = [];
  public customer: Customer = new Customer();
  public sliders: any[] = [];

  constructor(
    private router: Router,
    private productsService: ProductsService,
    private sessionStorageService: SessionStorageService,
    private loaderService: LoaderService
  ) {
    this.getUser();
    this.getImgSliders();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getImgSliders();
  }

  getImgSliders() {
    if (window.innerWidth > 640) {
      this.sliders = [
        { src: 'banner-8.webp', route: '/produtos?idProvider=13284' },
        { src: 'banner-1.webp', route: '/produtos?idProvider=14279' },
        { src: 'banner-2.webp', route: '/produtos?idProvider=13112' },
        { src: 'banner-3.webp', route: '/produtos?idProvider=12872' },
        { src: 'banner-4.webp', route: '/produtos?idProvider=13979' },
        { src: 'banner-5.webp', route: '/produtos?idProvider=12872' },
        { src: 'banner-6.webp', route: '/produtos?idProvider=11760' },
        { src: 'banner-7.webp', route: '/produtos?idProvider=12872' },
      ];
    } else {
      this.sliders = [
        { src: 'banner-8-mobile.webp', route: '/produtos?idProvider=13284' },
        { src: 'banner-1-mobile.webp', route: '/produtos?idProvider=14279' },
        { src: 'banner-2-mobile.webp', route: '/produtos?idProvider=13112' },
        { src: 'banner-3-mobile.webp', route: '/produtos?idProvider=12872' },
        { src: 'banner-4-mobile.webp', route: '/produtos?idProvider=13979' },
        { src: 'banner-5-mobile.webp', route: '/produtos?idProvider=12872' },
        { src: 'banner-6-mobile.webp', route: '/produtos?idProvider=11760' },
        { src: 'banner-7-mobile.webp', route: '/produtos?idProvider=12872' },
      ];
    }
  }

  getUser() {
    this.sessionStorageService.get(AuthConstants.AUTH).then((res) => {
      this.customer = new Customer();
      if (res) {
        this.customer.fromJson(res);
      }
      this.getProducts();
    });
  }

  async getProducts() {
    this.products = [];
    await this.loaderService.showLoader();
    try {
      const filter: ProductFilter = new ProductFilter();
      filter.offer = true;
      this.pageResponseProduct = await this.productsService.products(
        filter,
        30
      );
      if (this.pageResponseProduct) {
        this.products = this.pageResponseProduct.list as Product[];
      }
    } catch (error) {
      console.log(error);
    } finally {
      await this.loaderService.hideLoader();
      setTimeout(() => {
        window.dispatchEvent(new Event('refreshOwlCarouselOffer'));
      }, 500);
    }
  }

  onClickBannerFooter() {
    this.router.navigateByUrl(`produtos?idProvider=11760`);
  }

  onImgErro(event: any) {
    event.target.src = 'assets/img/products/no-image.jpg';
  }
}
